import { useState, createContext, useContext, useEffect, useCallback } from "react";
import { serverURL } from "../components/utils"
import { AuthenticationContext } from "../context/authentication";

export const ConfigContext = createContext();

export const ConfigProvider = ({ children }) => {
	const [genre, setGenre] = useState([]);
	const [danceTypes, setDanceTypes] = useState([]);
	const [filterType, setFilterType] = useState(5);
	const [filterGenre, setFilterGenre] = useState();
	const [offerType, setOfferType] = useState([]);
	const [offerGenre, setOfferGenre] = useState([]);
	const { user, getTeacher } = useContext(AuthenticationContext);
	
	const [teacherDances, setTeacherDances] = useState()
	const loadTeacherDances = useCallback(() => { 
		if (!user) { return }
		return new Promise((resolve, reject) => {
			fetch(`${serverURL}/gapi/api.php/teacherdances/${user.id}`,{
				headers: {
					"Accept": "application/json",
					"Content-Type": "application/json",
				}})
				.then((res) => res.json() )
				.then((res) => {
					if (res.errors) {
						throw res;
					}
					//console.log("TEACHERDANCES",res)
					setTeacherDances(res);
					resolve(res);
				})
				.catch((err) => {
					console.error("ERROR Loading dance types", err);
					reject(err);
				});
		});
	}, [user]);

	const changeTeacherDance = (genreid, canteach = true, level = 1) => {
		return new Promise((resolve, reject) => {
			let data = {userid: user.id, genreid: genreid, levelid: level, canteach: canteach};
			//console.log("Teacher update", data)
			fetch(`${serverURL}/api/addTeachLevel.php`, {
				method: 'POST',
				headers: {
					'Accept': 'application/json, text/plain, */*',
					'Content-Type': 'application/json'
				},
				body: JSON.stringify(data)
			})
				.then((res) => res.json() )
				.then((res) => {
					if (res.errors) {
						throw res;
					}
					// console.log("TEACHER DANCE UPDATE",res)
					loadTeacherDances();
					getTeacher(user.id)
					resolve(res);
				})
				.catch((err) => {
					console.error("Loading dance types", err);
					reject(err);
				});
		});
	}

	useEffect(() => {
		if (user) {
			// console.log("Load Teacher Dances")
			loadTeacherDances();
		}
	},[user, loadTeacherDances])

	const loadTypes = () => {
		setDanceTypes([
      { id: 1, name: "Afro-Latin", forlevel: 5 },
      { id: 2, name: "Ballroom", forlevel: 5 },
      { id: 3, name: "Ballet", forlevel: 5 },
      { id: 4, name: "Jazz", forlevel: 5 },
      { id: 5, name: "Modern", forlevel: 5 },
      { id: 6, name: "Traditional", forlevel: 5 },
      { id: 7, name: "Tap", forlevel: 5 },
      { id: 8, name: "DJ", forlevel: 6 },
      { id: 9, name: "Venue", forlevel: 8 },
      { id: 10, name: "Supplier", forlevel: 9 },
      { id: 11, name: "Event", forlevel: 7 },
    ]);
		// return new Promise((resolve, reject) => {
		// 	fetch(`${serverURL}/api/dancetype.json`,{
		// 		// fetch(`${serverURL}/gapi/api.php/dancetype/`,{
		// 		headers: {
		// 			"Accept": "application/json",
		// 			"Content-Type": "application/json",
		// 		}})
		// 		.then((res) => res.json() )
		// 		.then((res) => {
		// 			if (res.errors) {
		// 				throw res;
		// 			}
		// 			setDanceTypes(res);
		// 			resolve(res);
		// 		})
		// 		.catch((err) => {
		// 			console.error("Loading dance types", err);
		// 			reject(err);
		// 		});
		// });
	};

	const loadGenres = () => {
		setGenre([
      { id: 81, dancetypeid: 10, name: "Accessories" },
      { id: 46, dancetypeid: 5, name: "Aerial" },
      { id: 54, dancetypeid: 8, name: "Afro-Latin" },
      { id: 6, dancetypeid: 1, name: "AfroBeat" },
      { id: 55, dancetypeid: 8, name: "Ambient" },
      { id: 2, dancetypeid: 1, name: "Bachata - Dominican" },
      { id: 38, dancetypeid: 1, name: "Bachata - Sensual" },
      { id: 20, dancetypeid: 3, name: "Ballet" },
      { id: 30, dancetypeid: 6, name: "Baranya" },
      { id: 36, dancetypeid: 3, name: "Barre" },
      { id: 31, dancetypeid: 6, name: "Belly dancing" },
      { id: 29, dancetypeid: 6, name: "Bharatanatyam" },
      { id: 45, dancetypeid: 5, name: "Biodanza" },
      { id: 13, dancetypeid: 2, name: "Bolero" },
      { id: 23, dancetypeid: 5, name: "Burlesque" },
      { id: 47, dancetypeid: 1, name: "Cali Salsa" },
      { id: 24, dancetypeid: 5, name: "Capoeira" },
      { id: 14, dancetypeid: 2, name: "ChaCha" },
      { id: 51, dancetypeid: 6, name: "Champeta" },
      { id: 21, dancetypeid: 4, name: "Charleston" },
      { id: 68, dancetypeid: 11, name: "Classes" },
      { id: 25, dancetypeid: 5, name: "Contemporary" },
      { id: 56, dancetypeid: 8, name: "Contemporary Pop" },
      { id: 57, dancetypeid: 8, name: "Country" },
      { id: 48, dancetypeid: 6, name: "Cumbia" },
      { id: 79, dancetypeid: 10, name: "Dance and Movement Apparel" },
      { id: 78, dancetypeid: 10, name: "Dance Shoes" },
      { id: 67, dancetypeid: 11, name: "Dancing" },
      { id: 93, dancetypeid: 11, name: "Demonstration" },
      { id: 26, dancetypeid: 5, name: "Disco" },
      { id: 58, dancetypeid: 8, name: "Disco/Funk" },
      { id: 80, dancetypeid: 10, name: "Dress up / Costume hire" },
      { id: 59, dancetypeid: 8, name: "Electronica/Techno" },
      {
        id: 86,
        dancetypeid: 10,
        name: "Event Logistics (Drivers, security etc)",
      },
      { id: 85, dancetypeid: 10, name: "Event Organisers" },
      { id: 82, dancetypeid: 10, name: "Fitness Supplies" },
      { id: 32, dancetypeid: 6, name: "Flamenco" },
      { id: 94, dancetypeid: 11, name: "Flashmob" },
      { id: 60, dancetypeid: 8, name: "Folk" },
      { id: 15, dancetypeid: 2, name: "Foxtrot" },
      { id: 83, dancetypeid: 10, name: "Health and Wellness" },
      { id: 27, dancetypeid: 5, name: "Hip hop" },
      { id: 61, dancetypeid: 8, name: "Hip Hop" },
      { id: 62, dancetypeid: 8, name: "House" },
      { id: 33, dancetypeid: 6, name: "Irish" },
      { id: 41, dancetypeid: 6, name: "Israeli Rikud ei Am" },
      { id: 63, dancetypeid: 8, name: "Jazz" },
      { id: 16, dancetypeid: 2, name: "Jive" },
      { id: 34, dancetypeid: 6, name: "Kathak" },
      { id: 3, dancetypeid: 1, name: "Kizomba" },
      { id: 53, dancetypeid: 1, name: "Konpa" },
      { id: 74, dancetypeid: 9, name: "Live Music" },
      { id: 17, dancetypeid: 2, name: "Mambo" },
      { id: 49, dancetypeid: 6, name: "Mapale" },
      { id: 37, dancetypeid: 6, name: "Marabenta" },
      { id: 87, dancetypeid: 10, name: "Marketing, Design and PR" },
      { id: 95, dancetypeid: 11, name: "Movie and Photo Opportunities" },
      { id: 28, dancetypeid: 5, name: "Nia" },
      { id: 75, dancetypeid: 9, name: "Open for Breakfast" },
      { id: 77, dancetypeid: 9, name: "Open for Dinner" },
      { id: 76, dancetypeid: 9, name: "Open for Lunch" },
      { id: 35, dancetypeid: 6, name: "Pantsula" },
      { id: 52, dancetypeid: 5, name: "Paradance" },
      { id: 69, dancetypeid: 11, name: "Party" },
      { id: 90, dancetypeid: 10, name: "Photography / Videography" },
      { id: 39, dancetypeid: 5, name: "Pole" },
      { id: 89, dancetypeid: 10, name: "Printing" },
      { id: 18, dancetypeid: 2, name: "Quickstep" },
      { id: 64, dancetypeid: 8, name: "R and B/Soul" },
      { id: 65, dancetypeid: 8, name: "Reggae" },
      { id: 4, dancetypeid: 1, name: "Reggaeton" },
      { id: 40, dancetypeid: 5, name: "Roller Dance" },
      { id: 9, dancetypeid: 1, name: "Salsa - Cross Body on 1" },
      { id: 10, dancetypeid: 1, name: "Salsa - Cross Body on 2" },
      { id: 1, dancetypeid: 1, name: "Salsa - Cuban" },
      { id: 50, dancetypeid: 1, name: "Salsa Choke" },
      { id: 11, dancetypeid: 1, name: "Samba" },
      { id: 8, dancetypeid: 1, name: "Semba" },
      { id: 88, dancetypeid: 10, name: "Social Media" },
      { id: 43, dancetypeid: 7, name: "Soft Tap" },
      { id: 73, dancetypeid: 9, name: "Space for Conferences" },
      { id: 70, dancetypeid: 9, name: "Space for Dancing" },
      { id: 72, dancetypeid: 9, name: "Space for Events" },
      { id: 71, dancetypeid: 9, name: "Space for Promotions" },
      {
        id: 84,
        dancetypeid: 10,
        name: "Studio Services (Mirrors, Floors, Lighting etc)",
      },
      { id: 22, dancetypeid: 4, name: "Swing" },
      { id: 92, dancetypeid: 11, name: "Talks / discussions" },
      { id: 5, dancetypeid: 1, name: "Tango" },
      { id: 42, dancetypeid: 7, name: "Tap" },
      { id: 66, dancetypeid: 8, name: "Traditional" },
      { id: 19, dancetypeid: 2, name: "Waltz" },
      { id: 91, dancetypeid: 11, name: "Workshop" },
      { id: 12, dancetypeid: 1, name: "Zouk" },
      { id: 44, dancetypeid: 5, name: "Zumba" },
    ]);
		// return new Promise((resolve, reject) => {
		// 	fetch(`${serverURL}/gapi/api.php/genre/`,{
		// 		headers: {
		// 			"Accept": "application/json",
		// 			"Content-Type": "application/json",
		// 		}})
		// 		.then((res) => res.json() )
		// 		.then((res) => {
		// 			if (res.errors) {
		// 				throw res;
		// 			}
		// 			setGenre(res);
		// 			resolve(res);
		// 		})
		// 		.catch((err) => {
		// 			console.error("Loading genres", err);
		// 			reject(err);
		// 		});
		// });
	};

	useEffect(() => {
		loadTypes()
		loadGenres()
	},[])

	useEffect(() => {
		if (danceTypes) {
			setOfferType(danceTypes.filter(item => item.forlevel === filterType))
		}
	},[filterType, danceTypes])

	const getGenresForType = (typeid) => {
		// console.log("Genre", genre)
		return genre.filter(item => item.dancetypeid === typeid)
	}

	useEffect(() => {
		if (genre) {
			setOfferGenre(genre.filter(item => item.dancetypeid === filterGenre))
		}
	},[filterGenre, genre])

	return (
		<ConfigContext.Provider
			value={{
				offerType, offerGenre, 
				setFilterType, setFilterGenre, 
				getGenresForType,
				teacherDances, changeTeacherDance
			}}>
			{children}
		</ConfigContext.Provider>
	);
};

export default ConfigProvider;
