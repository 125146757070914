import React, { useState, useContext, useEffect } from "react";
import { Form, Row, Col, Button } from "react-bootstrap";
import { AuthenticationContext } from "../../context/authentication";
import { serverURL } from "../../components/utils";
import { Envelope, Link, Whatsapp } from "react-bootstrap-icons";
import PackageBooking from "./packagebookings";

const EventBooking = ({ event, eventid }) => {
  const { user } = useContext(AuthenticationContext);
  const [lead, setlead] = useState(false);
  const [follow, setfollow] = useState(false);
  const [leadLevel, setleadLevel] = useState(0);
  const [followLevel, setfollowLevel] = useState(0);
  const [booked, setBooked] = useState(false);

  useEffect(() => {
    fetch(
      `${serverURL}/api/booking/getbooking.php?eventid=${eventid}&profileid=${user.id}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => res.json())
      .then((res) => {
        if (res.errors) {
          throw res;
        }
        if (res.data.length > 0) {
          setBooked(true);
        }
      })
      .catch((err) => {
        setBooked(false);
        console.error("ERROR Admin Teacher Load", err);
      });
  }, [eventid, user]);

  const book = () => {
    const data = {
      eventid: eventid,
      profileid: user.id,
      status: 1,
      data: `{"lead":${lead},"leadlevel":${leadLevel}, "follow":${follow}, "followlevel": ${followLevel}}`,
    };
    fetch(serverURL + "/api/booking/api.php/booking", {
      method: "POST",
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((res) => {
        setBooked(true);
      })
      .catch((err) => {
        console.error("Upload error", err);
      });
  };

  if ([2,"2"].includes(event.managebookings)) {
    return (
      <div
        className="p-3 m-3"
        style={{
          width: "95%",
          borderRadius: "10px",
          border: "2px solid purple",
        }}
      >
        Please contact me by Whatsapp to make your booking<br/>
        <Button
          target="_blank"
          href={`https://wa.me/${event.bookingdata}/?text=I would like to make a booking for ${event.altname}`}
        >
          <Whatsapp /> Whatsapp
        </Button>
      </div>
    );
  }

  if ([3,"3"].includes(event.managebookings)) {
    return (
      <div
        className="p-3 m-3"
        style={{
          width: "95%",
          borderRadius: "10px",
          border: "2px solid purple",
        }}
      >
        Please send me an email to make your booking<br/>
        <Button
          target="_blank"
          href={`mailto://${event.bookingdata}/?subject=Booking for ${event.altname}`}
        >
          <Envelope /> Book by Email
        </Button>
      </div>
    );
  }

  if ([99,"99"].includes(event.managebookings)) {
    return (
      <div
        className="p-3 m-3"
        style={{
          width: "95%",
          borderRadius: "10px",
          border: "2px solid purple",
        }}
      >
        <PackageBooking event={event} eventid={eventid} />
      </div>
    );
  }

  if ([4,"4"].includes(event.managebookings)) {
    return (
      <div
        className="p-3 m-3"
        style={{
          width: "95%",
          borderRadius: "10px",
          border: "2px solid purple",
        }}
      >
        Please follow this link to make your booking<br/>
        <Button
          target="_blank"
          href={event.bookingdata}
        >
          <Link /> Book
        </Button>
      </div>
    );
  }  

  if (booked) {
    return (
      <div
        className="p-3 m-3"
        style={{
          width: "95%",
          borderRadius: "10px",
          border: "2px solid purple",
        }}
      >
        <h1>Thank you for booking</h1>
        <div>
          Tô secure your spot please make the full payment of R500 to:
          <br />
          <div className="ms-5">
            Account name: ATUM CREAT11ONS
            <br />
            Bank name: Standard Bank
            <br />
            Branch code: 7205
            <br />
            Account number: 20 028 058 9<br />
            SWIFT code: SBZAZAJJ
            <br />
            Reference: Your name
            <br />
          </div>
          Please Send proof of payment to dana@juzt.dance
          <br />
        </div>
      </div>
    );
  }

  if (!user) {
    return (
      <div
        className="p-3 m-3"
        style={{
          width: "95%",
          borderRadius: "10px",
          border: "2px solid purple",
        }}
      >
        <h1>Please login to book</h1>
      </div>
    );
  }

  return (
    <Form
      className="p-3 m-3 mb-5"
      style={{ width: "95%", borderRadius: "10px", border: "2px solid purple" }}
    >
      <Row>
        <Col >
          Full Name
        </Col>
        <Col>
          <Form.Control
            plaintext
            readOnly
            defaultValue={user.firstname + " " + user.lastname}
          />
        </Col>
        </Row>
        <Form.Group as={Row} className="mb-1" controlId="UserName">
          <Form.Label column sm="4">
            Do you Lead
          </Form.Label>
          <Col sm="4">
            <Form.Check
              inline
              type="radio"
              label={`Yes`}
              id={`disabled-default-lead`}
              checked={lead}
              onChange={(e) => setlead(e.target.checked)}
            />
            <Form.Check
              inline
              type="radio"
              label={`No`}
              id={`disabled-default-lead`}
              checked={!lead}
              onChange={(e) => setlead(e.target.checked)}
            />
          </Col>
          <Col sm="4">
            <Form.Select
              aria-label="Default select example"
              value={leadLevel}
              onChange={(e) => {
                setleadLevel(e.target.value);
              }}
            >
              <option>Your Level</option>
              <option value="1">Beginner</option>
              <option value="2">Improver</option>
              <option value="3">Intermediate</option>
              <option value="4">Advanced</option>
            </Form.Select>
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-1" controlId="UserName">
          <Form.Label column sm="4">
            Do you Follow
          </Form.Label>
          <Col sm="4">
            <Form.Check
              inline
              type="radio"
              label={`Yes`}
              id={`disabled-default-lead`}
              checked={follow}
              onChange={(e) => setfollow(e.target.checked)}
            />
            <Form.Check
              inline
              type="radio"
              label={`No`}
              id={`disabled-default-lead`}
              checked={!follow}
              onChange={(e) => setfollow(e.target.checked)}
            />
          </Col>
          <Col sm="4">
            <Form.Select
              aria-label="Default select example"
              value={followLevel}
              onChange={(e) => {
                setfollowLevel(e.target.value);
              }}
            >
              <option>Your Level</option>
              <option value="1">Beginner</option>
              <option value="2">Improver</option>
              <option value="3">Intermediate</option>
              <option value="4">Advanced</option>
            </Form.Select>
          </Col>
        </Form.Group>
        <Row>
          <Col sm={4}></Col>
          <Col>
            <small>You can select both Lead and Follow</small>
          </Col>
        </Row>
        <Row>
          <Col>
            <Button
              variant="primary"
              onClick={() => {
                book();
              }}
            >
              Book
            </Button>
          </Col>
        </Row>
    </Form>
  );
};

export default EventBooking;
